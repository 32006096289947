<template>
  <div class="help">
    <Sidebar />
    <br />
    <pre><h4>Help Page</h4></pre>
    <div>
      <b-card no-body>
        <b-tabs pills card vertical>
          <b-tab title="How to Make Your Picks." active>
            <b-card-text>
              <ul>
                <li>Load your bracket by clicking on the "My Bracket" menu item.</li>
                <li>If you are loading your bracket for the first time the bracket will display the teams and nothing else.</li>
                  <ul>
                    <li>Make your selection by clicking on the team name.</li>
                    <li>If your selection was saved you will see a border around the team name.  You may change your selection up until the due date for the game.  The due dates are put in by your bracket leader.</li>
                  </ul>
                <li>Continue selecting teams until you've made all the selections for a given round.</li>
              </ul>
            </b-card-text>
          </b-tab>
          <b-tab title="Understanding Your Bracket.">
            <b-card-text>
              <ul>
                <li>After the games are finished the leader of your group will choose the winners of the games, once that is completed the follow formatting will display as appropriate.</li>
                <ul>
                <li>Selected Teams Display:</li>
                  <ul>
                    <li><img src="../assets/selectedteam.png" alt="selectedteam"></li>
                  </ul>
                <li>Wins Display:</li>
                  <ul>
                    <li><img src="../assets/winselected.png" alt="win selected"></li>
                  </ul>
                <li>Losses Display:</li>
                  <ul>
                    <li><img src="../assets/lossselected.png" alt="loss selected"></li>
                  </ul>
                  </ul>
              </ul>
            </b-card-text>
          </b-tab>
          </b-tabs>
        </b-card>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';

export default {
  name: "help",
  components: {
    Sidebar
  },
  props: {

  }}
</script>

