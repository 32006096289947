<template>
  <div class="container-fluid row">
    <Sidebar />
    <div class="col">
        <router-view></router-view>
        <!-- <Results :results="items" :selectedBracket="selectedBracket" /> -->
    </div>
  </div>
</template>

<style>

.flex-column {
    flex-direction: column;
    justify-content: center;
    align-content:space-evenly;
}
.flex-column > div {
    flex-grow: 2;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
}


@supports (-ms-accelerator: true) {
  /* Edge only */

  .flex-column > div {
    justify-content: space-around;
    margin: auto;
    /* you can also add some other adjustments to size, margins etc to get the same positioning */
  }
}
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>

<script>
import APIService from '@/APIService';
import Sidebar from '@/components/Sidebar.vue';
//import { EventBus } from '@/EventBus';
const api = new APIService();

export default {
  name: "dashboard",
  components: {
    Sidebar
  },
  props: {

  },
  data() {
    return {
      groupList: null,
      bracketID: null,
      selectedBracket: null,
      items: []
    }
  },
  methods: {
    getGroupsbyUser() {
      return api.getGroupsbyUser(localStorage.getItem('userid'));
    },
    getResultData() {
      return api.getResults(localStorage.getItem('bracketid'));
    },
    async setResults(bracketID, bracketName) {
    this.bracketID = bracketID;
    this.selectedBracket = bracketName;
    localStorage.setItem('bracketid', bracketID);
    let results = await this.getResultData(localStorage.getItem('bracketid'))
    this.items = results[results.length-1];

    },
    refreshGroups() {
    this.getGroupsbyUser().then(result => {
      this.groupList= result.data;
      //To-Do will need to remove the next lines.
    })
    }
  },
  computed: {
    filteredGroups: function() {
      if (this.groupList != null) {
      var newgroup = [...new Set(this.groupList.map(i=>i.GroupName))]
      }
      return newgroup
    }

  },
  created: function () {
    this.getGroupsbyUser().then(result => {
      this.groupList= result.data;
      //To-Do will need to remove the next lines.
    })

  }
};/*
// 4: {…}
// Bottom: 2
// 
// BracketID: 1
// 
// Deadline: "2100-01-01T00:00:00.000Z"
// 
// GameNbr: 1
// 
// Result: null
// 
// RoundNbr: 6
// 
// SelectedID: null
// 
// Team1ID: 0
// 
// Team2ID: 0
// 
// Top: 3
// 
// WinnerID: 0*/
</script>