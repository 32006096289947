<template>
    <div id="loginCard" class="text-center">
        <b-alert :show="showError" variant="danger" class="mt-4">{{ errorMessage }}</b-alert>
    <b-card
        title="Forgot Credentials"
        tag="article"
        style="max-width: 20rem;"
        class="my-3 text-center"
    >
      <form>
        <label for="emailAddress" class="">Your Email Address</label>
        <b-form-input id="emailAddress" v-model="email" placeholder="Enter your Email Address"></b-form-input>
        <br/>
        <div class="mx-auto mt-4">
            <b-button v-if="isPassword==='password'" type="submit" @click="handleSubmit" variant="primary">Send Recovery Email</b-button>
            <b-button v-else type="submit" @click="handleSubmit" variant="primary">Get Username</b-button>
        </div>
      </form>
    </b-card>
  </div>
</template>
<style scoped>
    #loginCard {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
</style>
<script>
// @ is an alias to /src
import APIService from '@/APIService';
//import { EventBus } from '@/EventBus.js';
const apiService = new APIService();

export default {
  name: 'forgotpassword',
  components: {
  },
  data() {
    return {
        email: '',
        errorMessage: '',
        isPassword: this.checkSwitch(),
    }
  },
  computed: {
      showError() {
          return this.errorMessage.length > 0;
      }
  },
  methods: {
    async handleSubmit(e) {
        e.preventDefault();
        try {
            await apiService.postPasswordResetRequest(this.email, this.isPassword);
            await this.showResultMessage();
            this.$router.push('/login');
            }
        catch (error){
            console.log(error)
            let msg;
            switch (error.message) {
                case 'Request failed with status code 400':
                    msg = 'An email address is required.';
                    break;
                case 'Request failed with status code 403':
                    msg = 'That email address is not attached to a user profile.';
                    break;
                default:
                    msg = 'An unknown error occurred processing this request. Please Try again';
                    break;
            }
            this.errorMessage = msg; 
        }

    },
    checkSwitch(){
        return this.$route.params.switch;
    },
    showResultMessage() {
        return this.$bvModal.msgBoxOk("If the email address provided was found an email has been sent succesfully.", {
          title: 'Confirmation',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
        })
        }
  }
}
</script>
