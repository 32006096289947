<template> 
  <div v-if="results && results.length>0">
    <h3>{{ bracket === null ? 'hi' : bracket[0].BracketName + " Results" }}</h3>
    <br>
    <table id ="results" class="table table-sm center">
      <caption>Number of Wins By User</caption>
      <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">First Four</th>
          <th scope="col">Round of 64</th>
          <th scope="col">Round of 32</th>
          <th scope="col">Sweet 16</th>
          <th scope="col">Elite 8</th>
          <th scope="col">Final Four</th>
          <th scope="col">Finals</th>
          <th scope="col">Grand Total</th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in results" :key="index">
        <th scope="row">{{index+1}}</th>
        <td> {{item.Name}}</td>
        <td> {{item.FirstFour}}</td>
        <td> {{item.Roundof64}}</td>
        <td> {{item.Roundof32}}</td>
        <td> {{item.Sweet16}}</td>
        <td> {{item.Elite8}}</td>
        <td> {{item.FinalFour}}</td>
        <td> {{item.Championship}}</td>
        <td> {{item.GrandTotal}}</td>         
      </tr>
      </tbody>
    </table>
    <b-button class="mb-2 no-print" v-on:click="print()">Print Results</b-button>
  </div>
  <div v-else>
    <br />
    <p>There are no results for the bracket you have selected yet.</p>
  </div>

</template>

<style>

table {
  max-width: 1000px;
} 
.center {
  margin-left: auto;
  margin-right: auto;
}

th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>

<script>
import APIService from '@/APIService';
const api = new APIService();

export default {
  name: "results",
  components: {
 
  },
  props: {
    id: Number
  },
  data() {
    return {
      bracket: null,
      results: []
    }
  },
  watch: {
    '$route.params.id': function (id) {
      localStorage.setItem('bracketid', id);
      this.getResultData();
      this.getBracket();

    }
  },
  methods: {
    goToBracket () {
      this.$router.push("bracket/" + this.id);
    },
    print() {
      window.print();
    },
    async getResultData() {
      let items = await api.getResults(localStorage.getItem('bracketid'));
      this.results = items[items.length-1];
    },
    async getBracket() {
      this.bracket = await api.getBracket(localStorage.getItem('bracketid'));
    },
  },
  created: async function () {
      localStorage.setItem('bracketid', this.$route.params.id);
      this.getResultData();
      this.getBracket();
  }
};
</script>