<template>
  <b-sidebar
    id="sidebar-backdrop"
    title="Challenges"
    bg-variant="light"
    text-variant="dark"
    backdrop-variant="dark"
    backdrop
    shadow
  >
    <div class="h-100 px-0 position-fixed no-print z-1000" :class="sidebarStyles">
      <div class="sticky-top"> 
        <div class="col px-0">
          <div class="accordion" :id="`accordion-groups`">
            <div class="card text-dark bg-light" v-for="(groups, groupKey) in filteredGroups" :key="groups.groupKey">
              <div class="card-header pl-0" :id="`heading-${groupKey}`">
                <h2 class="mb-0">
                  <button class="btn btn-light btn-outline-dark btn-block text-left font-weight-bold no-print"  type="button" data-toggle="collapse" :data-target="`#accordion-${groupKey}`" :aria-expanded="groupKey == 0 ? 'true' : 'false'" :aria-controls="`accordion-${groupKey}`">
                    {{groups}}
                  </button>
                </h2>
              </div>
              <div :id="`accordion-${groupKey}`" :class="`collapse ${groupKey == 0 ? 'show' : ''}`" aria-labelledby="`heading-${groupKey}`" :data-parent="`#accordion-groups`">
                <div class="">
                  <!---->
                  <div class="accordion" :id="`accordion-brackets-${groupKey}`">
                    <div class="card" v-for="(brackets, bracketKey) in groupList.filter(b=> groups == b.GroupName)" :key="brackets.bracketKey">
                      <div class="card-header py-0" :id="`heading-${groupKey}-${bracketKey}`">
                        <h2 class="mb-0">
                          <button class="btn btn-outline-dark btn-block text-left" type="button" data-toggle="collapse" :data-target="`#accordion-${groupKey}-${bracketKey}`" aria-expanded="true" :aria-controls="`accordion-${groupKey}-${bracketKey}`">
                            
                            {{brackets.BracketName}}
                          </button>
                        </h2>
                      </div>
                      <div :id="`accordion-${groupKey}-${bracketKey}`" :class="`collapse ${bracketKey == 0 ? 'show' : ''}`" :aria-labelledby="`heading-${groupKey}-${bracketKey}`" :data-parent="`#accordion-brackets-${groupKey}`">
                        <div class="card-body">
                          <b-list-group>
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <router-link :to="`/dashboard/dashboard/${brackets.BracketID}`">Results</router-link>
                          </b-list-group-item> 
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <router-link :to="`/dashboard/bracket/${brackets.BracketID}`">Bracket</router-link>
                            <b-badge variant="danger" v-if="brackets.DuePicksCount != 0">Missing {{brackets.DuePicksCount}} Picks</b-badge>
                          </b-list-group-item> 
                          <b-list-group-item v-if="userID == brackets.GroupAdminID || userID == brackets.GroupSecondaryAdminID" class="d-flex justify-content-between align-items-center">
                            <router-link :to="`/dashboard/admin/${brackets.BracketID}`">Admin</router-link>
                          </b-list-group-item> 
                          </b-list-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!---->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-sidebar>
</template>
<style>

.flex-column {
    flex-direction: column;
    justify-content: center;
    align-content:space-evenly;
}
.flex-column > div {
    flex-grow: 2;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
}
.sidebar-toggle {
    position: absolute;
    z-index: 10000;
    right: .5em;
    top: 0;
    color: var(--primary);
    padding: .5em;
    cursor: pointer;
}

.sidebar-closed .sidebar-toggle {
  transform: rotate(180deg);
  right: 0;
}

.sidebar-closed {
  width: 1.5em;
}

.sidebar-open {
  width: 300px;
}

.sidebar-closed > *:not(.sidebar-toggle) {
  display: none;
}

.z-1000{
  z-index: 1000;
}

@supports (-ms-accelerator: true) {
  /* Edge only */

  .flex-column > div {
    justify-content: space-around;
    margin: auto;
    /* you can also add some other adjustments to size, margins etc to get the same positioning */
  }
}
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>

<script>
import APIService from '@/APIService';
//import Results from '@/components/Results.vue';
//import NewGroup from '@/components/NewGroup.vue';
import { EventBus } from '@/EventBus';
const api = new APIService();

export default {
  name: "dashboard",
  components: {
    //Results,
    // NewGroup
  },
  props: {

  },
  data() {
    return {
      groupList: null,
      userID: null,
      bracketID: null,
      selectedBracket: null,
      isSidebarOpen: true,
      items: []
    }
  },
  methods: {
    getGroupsbyUser() {
      return api.getGroupsbyUser(localStorage.getItem('userid'));
    },
    getResultData() {
      return api.getResults(localStorage.getItem('bracketid'));
    },
    async setResults(bracketID, bracketName) {
      this.bracketID = bracketID;
      this.selectedBracket = bracketName;
      localStorage.setItem('bracketid', bracketID);
      let results = await this.getResultData(localStorage.getItem('bracketid'))
      this.items = results[results.length-1];

    },
    refreshGroups() {
    this.getGroupsbyUser().then(result => {
      this.groupList= result.data;
      //To-Do will need to remove the next lines.
    })
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      
    }
  },
  computed: {
    filteredGroups: function() {
      if (this.groupList != null) {
      var newgroup = [...new Set(this.groupList.map(i=>i.GroupName))]
      }
      return newgroup
    },
    sidebarStyles() {
      return {
        'sidebar-open': this.isSidebarOpen,
        'sidebar-closed': !this.isSidebarOpen
      };
    },
  },
  created: function () {
    this.getGroupsbyUser().then(result => {
      this.groupList= result.data;
      this.userID = localStorage.getItem('userid')

      EventBus.$on('successful-team-selection', this.refreshGroups)

      //To-Do will need to remove the next lines.
    })

  },
  destroyed() {
    EventBus.$off('successful-team-selection', this.refreshGroups);
  }
};/*
// 4: {…}
// Bottom: 2
// 
// BracketID: 1
// 
// Deadline: "2100-01-01T00:00:00.000Z"
// 
// GameNbr: 1
// 
// Result: null
// 
// RoundNbr: 6
// 
// SelectedID: null
// 
// Team1ID: 0
// 
// Team2ID: 0
// 
// Top: 3
// 
// WinnerID: 0*/
</script>